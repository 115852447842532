<!--
 * @Author: your name
 * @Date: 2021-03-08 17:45:10
 * @LastEditTime: 2021-03-08 17:47:41
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /iot-product-tool/src/components/nav.vue
-->
<template>
  <div class="components-nav">
      <div class="logo-name" v-if="isLexing">旺博</div>
      <div class="logo-name" v-else>九耳</div>

      <div class="link-box">
          <router-link v-for="(item, index) in links" :to="item.href" :key="index">{{item.text}}</router-link>
      </div>
  </div>
</template>

<script>
export default {
  name: 'Nav',
  data() {
        const vm = this;
        const uuid = vm.$localStorage.getItem('uuid')
        const fType = vm.$strTool.filterFactoryType(uuid);
        const links = [
                {text: '返厂维护', href: '/ryProbe/afterSale/list', active: true},
                {text: '出厂校验', href: '/ryProbe/preSale/list'}
        ]
        fType == 'lexing' && links.shift()
        return {
            isLexing: fType == 'lexing',
            links
        }
  },
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less">
@import "@/less/base.less";
.components-nav {
    width: 160px;
    background: linear-gradient(335deg, #FFFFFF 0%, rgba(92, 191, 92, 0.59) 29%, rgba(48, 190, 51, 0.84) 39%, rgba(33, 155, 85, 0.99) 100%, rgba(33, 156, 84, 0.99) 100%, #209A56 100%, #209A56 100%);
    height: 100%;
    box-sizing: border-box;
    padding: 30px 20px 0;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
    color: @blank;
    .logo-name {
        font-size: 40px;
        text-shadow: 12px 4px 20px rgba(0, 0, 0, 0.5);
    }

    .link-box {
        padding-top: 20px;
        a {
            display: block;
            color: @blank;
            font-size: 26px;
            padding-bottom: 20px;
            font-weight: lighter;
            &.router-link-active {
                font-weight: bold;
                font-size: 28px;
            }
        }
    }
}
</style>
